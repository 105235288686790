const routes = [
  {
    path: "general",
    name: "userGeneralTab",
    component: () => import("./General/General.vue"),
    meta: { labelKey: "GENERAL", sidebar: { name: "users" } }
  },
  {
    path: "access_control",
    name: "userAccessControlTab",
    component: () => import("./AccessControl/AccessControl.vue"),
    meta: { labelKey: "ACCESS_CONTROL", sidebar: { name: "users" } }
  }
];

export default routes;
