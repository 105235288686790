import userRoutes from "./User/routes.js";

const domainRoute = {
  path: "/users",
  name: "users",
  component: () => import("./Users/Users.vue"),
  meta: {
    labelKey: "USERS",
    sidebar: { display: true, icon: "users", name: "users" }
  }
};

const routes = [
  domainRoute,
  {
    path: "/users/onboard",
    name: "onboardUser",
    meta: {
      labelKey: "ONBOARD_USER",
      parent: domainRoute,
      sidebar: { name: "users" }
    },
    component: () => import("./OnboardUser/OnboardUser.vue")
  },
  {
    path: "/users/:userId",
    meta: {
      labelKey: "USER",
      getLabel: ({ user }) => user,
      parent: domainRoute
    },
    component: () => import("./User/User.vue"),
    children: userRoutes
  }
];

export default routes;
