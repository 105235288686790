import auth0 from "auth0-js";
import AUTH0_CONFIG from "@/config/auth0";

const webAuth = new auth0.WebAuth(AUTH0_CONFIG);

const state = () =>
  ["token", "accessToken", "expiresAt", "user"].reduce(
    (acc, key) => ({ ...acc, [key]: JSON.parse(localStorage.getItem(key)) }),
    {}
  );

const getters = {
  isAuthenticated: ({ expiresAt }) => new Date().getTime() < expiresAt
};

const mutations = {
  setAuth(state, auth) {
    Object.keys(state).map(key => {
      state[key] = auth[key];
      localStorage.setItem(key, JSON.stringify(auth[key]));
    });
  },
  removeAuth(state) {
    Object.keys(state).map(key => {
      state[key] = null;
      localStorage.removeItem(key);
    });
  }
};

const actions = {
  login() {
    webAuth.authorize();
  },
  logout({ commit, dispatch }) {
    commit("removeAuth");
    webAuth.logout();
    dispatch("login");
  },
  handleAuthentication({ commit, dispatch }) {
    return webAuth.parseHash((_err, authResult) => {
      if (!authResult) return dispatch("logout");

      history.replaceState("", "", "/");
      const { idToken, accessToken, expiresIn, idTokenPayload } = authResult;

      return commit("setAuth", {
        token: idToken,
        accessToken,
        expiresAt: expiresIn * 1000 + new Date().getTime(),
        user: idTokenPayload
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
