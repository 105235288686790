var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Menu',{staticClass:"sidebar-menu",class:{ 'collapsed-sidebar-menu': _vm.isCollapsed },attrs:{"theme":"dark","width":"auto","active-name":_vm.activeName}},_vm._l((_vm.routes),function(ref){
    var path = ref.path;
    var ref_meta = ref.meta;
    var labelKey = ref_meta.labelKey;
    var ref_meta_sidebar = ref_meta.sidebar;
    var icon = ref_meta_sidebar.icon;
    var name = ref_meta_sidebar.name;
return _c('MenuItem',{key:name,staticClass:"sidebar-menu-item",attrs:{"name":name,"to":path}},[_c('fa',{attrs:{"icon":icon,"size":"lg","fixed-width":""}}),_c('span',{staticClass:"pl-2 poppins"},[_vm._v(_vm._s(_vm.$t(labelKey)))])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }