<template>
  <Breadcrumb>
    <BreadcrumbItem
      v-for="{
        name,
        path,
        params = {},
        meta: { labelKey, getLabel = () => '' }
      } in crumbs"
      :key="path"
      :to="{ name, params }"
      class="poppins"
    >
      {{ getLabel(state) || $t(labelKey) }}
    </BreadcrumbItem>
  </Breadcrumb>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("breadcrumb");

export default {
  computed: {
    ...mapGetters(["state"]),
    crumbs() {
      return this.$route.matched.reduce(
        (crumbs, { meta, meta: { parent }, ...rest }) => [
          ...crumbs,
          ...(parent ? [parent] : []),
          { ...rest, meta }
        ],
        []
      );
    }
  }
};
</script>

<style scoped></style>
