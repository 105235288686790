const AUTH0_CONFIG = {
  domain: "delight-data.eu.auth0.com",
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin,
  // we will use the api/v2/ to access the user information as payload
  audience: "https://delight-data.eu.auth0.com/api/v2/",
  responseType: "token id_token",
  scope: "openid profile"
};

export default AUTH0_CONFIG;
