<template>
  <div class="layout">
    <Layout :style="{ minHeight: '100vh' }">
      <Sider collapsible :collapsed-width="65" v-model="isCollapsed">
        <div
          style="height: 64px"
          class="bg-gray-900 shadow p-2"
          :class="{ collapsed: isCollapsed }"
        >
          <img class="h-12 inline-block" src="../assets/logo-transparent.png" />
          <span
            class="inline-block text-white text-sm align-middle poppins p-2"
          >
            Access Gateway
          </span>
        </div>
        <SidebarMenu :is-collapsed="isCollapsed" />
      </Sider>
      <Layout>
        <Header class="bg-white shadow px-3">
          <Breadcrumb />
        </Header>
        <Content class="p-4">
          <slot></slot>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import SidebarMenu from "./components/SidebarMenu/SidebarMenu.vue";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb.vue";

export default {
  components: { SidebarMenu, Breadcrumb },
  data() {
    return {
      isCollapsed: false
    };
  }
};
</script>

<style scoped>
.collapsed {
  text-align: center;
}
.collapsed span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0px;
  padding: 0;
  transition: width 0.2s ease;
}
</style>
