<template>
  <Menu
    theme="dark"
    width="auto"
    class="sidebar-menu"
    :class="{ 'collapsed-sidebar-menu': isCollapsed }"
    :active-name="activeName"
  >
    <MenuItem
      v-for="{
        path,
        meta: {
          labelKey,
          sidebar: { icon, name }
        }
      } in routes"
      :key="name"
      :name="name"
      :to="path"
      class="sidebar-menu-item"
    >
      <fa :icon="icon" size="lg" fixed-width></fa>
      <span class="pl-2 poppins">{{ $t(labelKey) }}</span>
    </MenuItem>
  </Menu>
</template>

<script>
import routes from "@/config/routes";

export default {
  props: { isCollapsed: { type: Boolean, default: false } },
  computed: {
    routes: () =>
      routes.filter(
        ({ meta: { sidebar: { display } = { display: false } } }) => display
      ),
    activeName() {
      const { sidebar: { name } = { name: "" } } = this.$route.meta;

      return name;
    }
  }
};
</script>

<style scoped>
.sidebar-menu span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

.sidebar-menu > .sidebar-menu-item {
  padding: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
}

.collapsed-sidebar-menu span {
  display: none;
}

.collapsed-sidebar-menu > .sidebar-menu-item {
  text-align: center;
}
</style>
