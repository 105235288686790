const state = () => ({ organization: null, user: null });

const getters = { state: state => state };

const mutations = { addAssets: Object.assign };

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
