const routes = [
  {
    path: "",
    name: "organizationGeneralTab",
    component: () => import("./General/General.vue"),
    meta: { labelKey: "GENERAL", sidebar: { name: "organizations" } }
  },
  {
    path: "users",
    name: "organizationUsersTab",
    component: () => import("./Users/Users.vue"),
    meta: { labelKey: "USERS", sidebar: { name: "organizations" } }
  },
  {
    path: "roles",
    name: "organizationRolesTab",
    component: () => import("./Roles/Roles.vue"),
    meta: { labelKey: "ROLES", sidebar: { name: "organizations" } }
  },
  {
    path: "das_applications",
    name: "organizationQlikApplicationsTab",
    component: () => import("./QlikApplications/QlikApplications.vue"),
    meta: { labelKey: "QLIK_APPLICATIONS", sidebar: { name: "organizations" } }
  },
  {
    path: "quotas",
    name: "organizationQuotasTab",
    component: () => import("./Quotas/Quotas.vue"),
    meta: { labelKey: "QUOTAS", sidebar: { name: "organizations" } }
  }
];

export default routes;
