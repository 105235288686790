import dashboardRoutes from "@/Dashboard/routes.js";
import organizationsRoutes from "@/Organizations/routes.js";
import usersRoutes from "@/Users/routes.js";
import unauthorizedRoutes from "@/Unauthorized/routes.js";

const routes = [
  ...dashboardRoutes,
  ...organizationsRoutes,
  ...usersRoutes,
  ...unauthorizedRoutes
];

export default routes;
