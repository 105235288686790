import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import breadcrumb from "./modules/breadcrumb";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, breadcrumb }
});
