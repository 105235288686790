import organizationRoutes from "./Organization/routes.js";

const domainRoute = {
  path: "/organizations",
  name: "organizations",
  component: () => import("./Organizations/Organizations.vue"),
  meta: {
    labelKey: "ORGANIZATIONS",
    sidebar: { display: true, icon: "sitemap", name: "organizations" }
  }
};

const routes = [
  domainRoute,
  {
    path: "/organizations/:organizationId",
    meta: {
      labelKey: "ORGANIZATION",
      getLabel: ({ organization }) => organization,
      parent: domainRoute
    },
    component: () => import("./Organization/Organization.vue"),
    props: true,
    children: organizationRoutes
  },
  {
    path: "/organizations/onboard",
    name: "onboardOrganization",
    meta: {
      labelKey: "ONBOARD_ORGANIZATION",
      parent: domainRoute,
      sidebar: { name: "organizations" }
    },
    component: () => import("./OnboardOrganization/OnboardOrganization.vue")
  }
];

export default routes;
