<template>
  <div id="app">
    <div v-if="!me" class="h-screen flex justify-center items-center">
      <div class="inline-block">
        <img
          class="mx-auto rotate"
          src="./assets/logo.png"
          alt="Delight Access Gateway logo"
        />
        <h1 class="p-3 text-5xl">Access Gateway</h1>
      </div>
    </div>
    <Layout v-else>
      <router-view />
    </Layout>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Layout from "./Layout/Layout.vue";

const { mapGetters, mapActions } = createNamespacedHelpers("auth");
import gql from "graphql-tag";

export default {
  name: "app",
  components: { Layout },
  created() {
    if (!this.isAuthenticated) {
      this.handleAuthenticationPromise = this.handleAuthentication();
    }
  },
  async mounted() {
    await this.handleAuthenticationPromise;
  },
  data() {
    return { me: null };
  },
  apollo: {
    me: {
      query: gql`
        {
          userMe {
            id
          }
        }
      `,
      update: ({ userMe }) => userMe,
      skip() {
        return !this.isAuthenticated;
      }
    }
  },
  computed: mapGetters(["isAuthenticated"]),
  methods: mapActions(["handleAuthentication"])
};
</script>

<style scoped>
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
