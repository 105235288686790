var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Breadcrumb',_vm._l((_vm.crumbs),function(ref){
    var name = ref.name;
    var path = ref.path;
    var params = ref.params; if ( params === void 0 ) params = {};
    var ref_meta = ref.meta;
    var labelKey = ref_meta.labelKey;
    var getLabel = ref_meta.getLabel; if ( getLabel === void 0 ) getLabel = function () { return ''; };
return _c('BreadcrumbItem',{key:path,staticClass:"poppins",attrs:{"to":{ name: name, params: params }}},[_vm._v(" "+_vm._s(getLabel(_vm.state) || _vm.$t(labelKey))+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }