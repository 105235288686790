import Dashboard from "@/Dashboard/Dashboard.vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      labelKey: "DASHBOARD",
      sidebar: { display: true, icon: "tachometer-fast", name: "dashboard" }
    }
  }
];

export default routes;
