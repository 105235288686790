const routes = [
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("./Unauthorized.vue"),
    meta: { labelKey: "UNAUTHORIZED" }
  }
];

export default routes;
