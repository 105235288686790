import Vue from "vue";
import App from "./App.vue";
import VueRollbar from "vue-rollbar";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./plugins/view-design.js";
import "./assets/tailwind.css";
import "./assets/AccessGateway.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createProvider } from "./vue-apollo";

// Check aliases for vee-validate in vue.config.js
import { localize } from "vee-validate-full";
import fr from "vee-validate/dist/locale/fr";

import Clipboard from "v-clipboard";

library.add(fas, far, fal, fab);
Vue.component("fa", FontAwesomeIcon);

localize("fr", fr);
Vue.use(Clipboard);

Vue.config.productionTip = false;

const { VUE_APP_ROLLBAR_TOKEN, NODE_ENV } = process.env;

if (NODE_ENV === "production") {
  Vue.use(VueRollbar, {
    accessToken: VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    source_map_enabled: true,
    environment: NODE_ENV
  });

  Vue.config.errorHandler = Vue.rollbar.error;
}

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
